import { RoleGroup } from "../typings/api/role-group";
import APIService from "./api.service";

// ID values copied from database.
export const roleGroups = {
    SuperUser: 4,
    PublicAccess: 5,
    ERCClient: 6,
    Affiliate: 7,
    Accountant: 8,
    AccountManager: 9,
    Debug: 10,
    Closer: 11,
    AffiliateInTraining: 12,
    InternalCpa: 13,
    DocCollector: 14,
    TaxAttorney: 15,
    InternalDocCollector: 16,
    SalesManager: 17,
    Paymaster: 18,
    Owner: 19,
    Bookkeeper: 20,
    Agent: 21,
    CFO: 22,
    HedgeFund: 23,
};

// Keep in sync with API.
export const roleAssignments = {
    [roleGroups.SuperUser]: [...Object.values(roleGroups)],
    [roleGroups.PublicAccess]: [],
    [roleGroups.ERCClient]: [],
    [roleGroups.Affiliate]: [
        roleGroups.ERCClient,
        roleGroups.Affiliate,
        roleGroups.PublicAccess,
        roleGroups.AffiliateInTraining,
    ],
    [roleGroups.Accountant]: [],
    [roleGroups.AccountManager]: [],
    [roleGroups.AffiliateInTraining]: [roleGroups.Affiliate],
    [roleGroups.Owner]: [roleGroups.Bookkeeper, roleGroups.DocCollector, roleGroups.TaxAttorney],
    [roleGroups.Agent]: [],
    [roleGroups.CFO]: [],
    [roleGroups.HedgeFund]: [],
};

class RoleGroupService extends APIService<RoleGroup> {
    constructor() {
        super("role-groups");
    }

    async getAllRoleGroups() {
        const filter = {
            include: ["roles"],
        };
        const response = await this.getFiltered(filter);
        if (response && response.data) {
            response.data = response.data.map((entry) => {
                if (!entry.roles) {
                    entry.roles = [];
                }
                return entry;
            });
        }
        return response;
    }
}

export default new RoleGroupService();
