import { Search, Option, Detail, Theme } from "searchpal";
import ButtonNeoGen from ".//button-neogen";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import Avatar from "react-avatar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Preview } from "@storybook/react";
import { Md5 } from "../sections/utilities/md5";
import PrintPre from "./print-pre";
import Badge from "./badge";
import theme from "./user-company-picker-theme";
import { Company } from "../companies/domain/company";

("./user-company-picker.css");

export default function UserCompanyPicker({
    open,
    setOpen,
    showUsers = true,
    showCompanies = true,
    userList = [],
    companyList = [],
    label = "Start typing to find a user or company...",
    setUser,
    setCompany,
    children,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    showUsers?: boolean;
    showCompanies?: boolean;
    userList?: ClearERCUser[];
    companyList?: Company[];
    label?: string;
    setUser?: (user: ClearERCUser) => void;
    setCompany?: (company: Company) => void;
    children?: React.ReactNode;
}) {
    return (
        <div>
            <Search
                theme={theme}
                label={label}
                open={open}
                onClose={() => setOpen(false)}
                link={({ href, children }) => <a href={href}>{children}</a>}
            >
                {showUsers &&
                    userList?.map((user: ClearERCUser) => (
                        <Option
                            label={user?.name ?? "No name"}
                            sublabel={user?.email}
                            media={
                                (
                                    <img
                                        alt="User Image"
                                        src={
                                            "https://www.gravatar.com/avatar/" +
                                            Md5.hashAsciiStr(user?.email ?? "") +
                                            "?d=retro"
                                        }
                                    />
                                ) ?? <>b</>
                            }
                            onClick={() => {
                                setUser?.(user);
                            }}
                            button={
                                <ButtonNeoGen
                                    className="w-full m-0 "
                                    size="xl"
                                    type="primary"
                                    onClick={() => {
                                        setUser?.(user);
                                    }}
                                >
                                    Select User
                                </ButtonNeoGen>
                            }
                            preview={
                                (
                                    <img
                                        alt="User Image"
                                        src={
                                            "https://www.gravatar.com/avatar/" +
                                            Md5.hashAsciiStr(user?.email ?? "") +
                                            "?s=256"
                                        }
                                    />
                                ) ?? <>a</>
                            }
                            keywords={(getKeywords) =>
                                getKeywords(user?.email, user?.name, user?.firstName, user?.lastName, user?.phone)
                            }
                            key={user?.id}
                        >
                            <Detail
                                label="User Details"
                                value={
                                    <div>
                                        <div>
                                            <span className="font-bold mr-3">Name:</span>
                                            <span className="">
                                                {user.firstName} {user.lastName}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="font-bold mr-3">Email:</span>
                                            <span className="">{user.email}</span>
                                        </div>
                                        <div>
                                            <span className="font-bold mr-3">Phone:</span>
                                            <span className="">{user.phone}</span>
                                        </div>
                                        <div>
                                            <span className="font-bold mr-3">Role Groups:</span>
                                            <span className="">
                                                {user.roleGroups?.map((rg) => {
                                                    return <Badge label={rg.name} key={rg.id} color="purple" />;
                                                })}
                                            </span>
                                            {/* <span className="bg-green-500">
                                                {user.roleGroups?.map((rg) => {
                                                    return (
                                                        <span key={rg.id} color="purple">
                                                            {rg.name}
                                                        </span>
                                                    );
                                                })}
                                            </span> */}
                                        </div>
                                        <div>
                                            {user.lastLoggedIn === null ||
                                            user.lastLoggedIn === undefined ||
                                            isNaN(new Date(user.lastLoggedIn).getTime()) ? (
                                                <span className="font-bold text-red-400">Never Logged In</span>
                                            ) : (
                                                <>
                                                    <span className="font-bold mr-3">Last Logged In:</span>
                                                    <span className="">
                                                        {new Date(user.lastLoggedIn ?? "").toLocaleString()}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </Option>
                    ))}
                {showCompanies &&
                    companyList?.map((company) => (
                        <Option
                            label={company?.name ?? "No name"}
                            sublabel={company?.ownedBy?.email}
                            media={<span className="ml-2 fa fa-building" />}
                            preview={<span className="ml-2 fa-4x fa fa-building" />}
                            keywords={(getKeywords) => getKeywords(company?.email, company?.name, company?.phone)}
                            key={company?.id}
                            onClick={() => {
                                setCompany?.(company);
                            }}
                            button={
                                <ButtonNeoGen
                                    className="w-full m-0"
                                    size="xl"
                                    type="primary"
                                    onClick={() => {
                                        setCompany?.(company);
                                    }}
                                >
                                    Select Company
                                </ButtonNeoGen>
                            }
                        >
                            <Detail
                                label="Owner Details: "
                                value={
                                    <div>
                                        {/* <PrintPre>{company}</PrintPre> */}
                                        {company.ownedBy?.firstName} {company.ownedBy?.lastName}
                                        <div>
                                            <span className="font-bold mr-3">Name:</span>
                                            <span className="">
                                                {company.ownedBy?.firstName} {company.ownedBy?.lastName}
                                            </span>
                                        </div>
                                        <div>
                                            <a href={"mailto:" + company.ownedBy?.email}>
                                                <span className="font-bold mr-3">Email:</span>
                                                <span className="" title={company.ownedBy?.email}>
                                                    {company.ownedBy?.email?.length ?? 0 > 20
                                                        ? company.ownedBy?.email.substring(0, 17) + "..."
                                                        : company.ownedBy?.email}
                                                </span>
                                            </a>
                                        </div>
                                        <div>
                                            <a href={"tel:" + company.ownedBy?.phone}>
                                                <span className="font-bold mr-3">Phone:</span>
                                                <span className="">{company.ownedBy?.phone}</span>
                                            </a>
                                        </div>
                                        <div>
                                            <span className="font-bold mr-3">Role Groups:</span>
                                            <span className="">
                                                {company.ownedBy?.roleGroups?.map((rg: any) => {
                                                    return (
                                                        <span key={rg.id}>
                                                            {rg.name}
                                                            <br />
                                                        </span>
                                                    );
                                                })}
                                            </span>
                                        </div>
                                        <div>
                                            {company.ownedBy?.lastLoggedIn === null ||
                                            company.ownedBy?.lastLoggedIn === undefined ||
                                            isNaN(new Date(company.ownedBy?.lastLoggedIn).getTime()) ? (
                                                <span className="font-bold text-red-400">Never Logged In</span>
                                            ) : (
                                                <>
                                                    <span className="font-bold mr-3">Last Logged In:</span>
                                                    <span className="">
                                                        {new Date(company.ownedBy?.lastLoggedIn ?? "").toLocaleString()}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </Option>
                    ))}
            </Search>
            {children}
        </div>
    );
}
