import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { useMutation } from "@tanstack/react-query";
import { updateUser } from "../../../jason-proof-of-concept/users/actions/update-user";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { replaceCompanyRoles } from "../../../jason-proof-of-concept/users/actions/replace-company-roles";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../../layout/form/select-field";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { EmailField } from "../../../layout/form/email-field";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import authService from "../../../services/auth.service";
import { roleAssignments } from "../../../services/role-group.service";

const schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    phone: z.string().optional(),
    roleId: z.number().optional(),
});

type Data = z.infer<typeof schema>;

export const EditUserModal = ({
    onClose,
    user,
    currentRole,
    onUserUpdated,
    companyId,
    companyRoles,
}: {
    onClose: () => any;
    user: User;
    currentRole?: RoleGroup;
    onUserUpdated: (updatedUser: User) => any;
    companyId: number;
    companyRoles?: NonNullable<User["companyRoleGroups"]>;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    if (!user.id) {
        throw new Error("User must have an id");
    }
    const userId = user.id as string;

    const form = useForm({
        schema,
        defaultValues: {
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email,
            phone: user.phone || "",
            roleId: currentRole?.id,
        },
    });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedUser = await updateUser({
                authToken,
                id: userId,
                data,
            });
            if (data.roleId) {
                await replaceCompanyRoles({ authToken, id: userId, data: { companyId, roleId: data.roleId } });
            }
            return updatedUser;
        },
    });

    const handleSubmit = async (data: Data) => {
        const updatedUser = await mutation.mutateAsync(data);
        onUserUpdated(updatedUser as any);
    };

    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = companyRoles
        ? companyRoles.filter((rg) => roleGroupsIds.includes(rg.id || 9999))
        : undefined;

    return (
        <ModalDialog show title={"Edit user"} close={onClose} showOk={false} showCancel={false}>
            <div>
                <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                    <TextField label="First name" {...form.getFieldProps("firstName")} />
                    <TextField label="Last name" {...form.getFieldProps("lastName")} />
                    <EmailField label="Email" {...form.getFieldProps("email")} />
                    <TextField label="Phone" {...form.getFieldProps("phone")} />
                    {finalRoleGroups !== undefined && (
                        <SelectField
                            label="Role"
                            {...form.getFieldProps("roleId")}
                            options={finalRoleGroups.map((r) => ({ label: r.name || "", value: r.id || "" }))}
                        />
                    )}
                    <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                        Update user
                    </ButtonNeoGen>
                </Form>
            </div>
        </ModalDialog>
    );
};
