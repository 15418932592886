import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CreateUserData } from "../../jason-proof-of-concept/users/actions/create-user";
import { companySchema } from "../domain/company";
import { CreateCompanyData } from "./create-company";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";

export type SetupCompanyData = {
    company: CreateCompanyData;
    owner: CreateUserData;
    ownerPassword?: string;
    ownerRoleId?: number;
    w2Employees?: { 2020?: number; 2021?: number };
    members?: { user: CreateUserData; userCompany: any }[];
};

const responseSchema = z.object({
    company: companySchema,
    owner: userSchema,
});

export const setupCompany = async ({ authToken, data }: { authToken: string; data: SetupCompanyData }) => {
    const response = await makeRequest({
        method: "post",
        path: `/companies/setup`,
        authToken,
        data,
    });

    const { company, owner } = parseSchema(responseSchema, response.data);

    return { company, owner };
};
