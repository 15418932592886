import { makeRequest } from "../../jason-proof-of-concept/shared/utils";

export const updateUserCompany = async ({
    id,
    data,
    authToken,
}: {
    id: number;
    data: { commissionPercentage?: number };
    authToken?: string;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/user-companies/${id}`,
        data,
        authToken,
    });
};
