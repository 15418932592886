import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { useMutation } from "@tanstack/react-query";
import { Interaction } from "../domain/interaction";
import { useCreateInteraction } from "../hooks/use-create-company";
import { TextAreaField } from "../../layout/form/text-area-field";
import { useAuth } from "../../auth/use-auth";
import { CheckboxField } from "../../layout/form/checkbox-field";
import { Company } from "../../companies/domain/company";

const schema = z.object({
    activity: z.string().min(1),
    isInternal: z.boolean(),
});

type Data = z.infer<typeof schema>;

export const NewNoteModal = ({
    onClose,
    onNoteCreated: onNoteCreated,
    forUserId,
    company,
}: {
    onClose: () => any;
    onNoteCreated: (interaction: Interaction) => any;
    forUserId: string;
    company: Company;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema });
    const auth = useAuth();
    const user = auth.expectUser();

    const createInteractionMutation = useCreateInteraction();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const interaction = await createInteractionMutation.mutateAsync({
                authToken,
                data: {
                    activity: data.activity,
                    interaction_to: forUserId,
                    interaction_from: user.id,
                    type: 3, // Note
                    isInternal: data.isInternal,
                    companyId: company.id,
                },
            });
            return interaction;
        },
    });

    const handleSubmit = async (data: Data) => {
        const interaction = await submitMutation.mutateAsync(data);
        onNoteCreated(interaction);
    };

    return (
        <ModalDialog show title={"New Note"} close={onClose} showOk={false} showCancel={false} size="sm">
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={createInteractionMutation.error as any}>
                <TextAreaField label="Note" {...form.getFieldProps("activity")} placeholder="Add note..." />
                <CheckboxField label="Internal note" {...form.getFieldProps("isInternal")} />
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen
                        type="outline"
                        disabled={createInteractionMutation.isLoading}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createInteractionMutation.isLoading}>
                        Create Note
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
