import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userResponseSchema } from "../../jason-proof-of-concept/users/actions/get-users";

export type InviteUserData = {
    email: string;
    roleId: number;
};

export const inviteUserToCompany = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: InviteUserData;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/companies/${id}/invite-user`,
        authToken,
        data,
    });

    const user = parseSchema(userResponseSchema, response.data);

    return user;
};
