import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useMemo } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { useUpdateCompany } from "../../hooks/use-update-company";

const schema = z.object({
    name: z.string().min(1),
    address: z
        .object({
            line1: z.string().nullish(),
            city: z.string().nullish(),
            state: z.string().nullish(),
            country: z.string().nullish(),
        })
        .nullish(),
});

type Data = z.infer<typeof schema>;

export const EditCompanyModal = ({
    onClose,
    company,
    onCompanyUpdated: onCompanyUpdated,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: {
            name: company.name || undefined,
            address: {
                line1: company.address,
                city: company.city,
                state: company.state,
                country: company.country,
            },
        },
    });

    const updateCompanyMutation = useUpdateCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    name: data.name,
                    address: data.address?.line1,
                    city: data.address?.city,
                    state: data.address?.state,
                    country: data.address?.country,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <>
            <ModalDialog show title={"Edit Company"} close={onClose} showOk={false} showCancel={false} size="sm">
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <TextField label="Name" {...form.getFieldProps("name")} placeholder="ACME Corp" />
                    <h3>Address</h3>
                    <TextField
                        label="Line 1"
                        {...form.getFieldProps("address.line1")}
                        placeholder="513 Ocean View Way"
                    />
                    <div className="flex flex-row gap-5">
                        <div className="flex-1">
                            <TextField label="City" {...form.getFieldProps("address.city")} />
                        </div>
                        <div className="flex-1">
                            <TextField label="State" {...form.getFieldProps("address.state")} />
                        </div>
                    </div>
                    <TextField label="Country" {...form.getFieldProps("address.country")} />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update Company
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
