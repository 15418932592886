/* eslint-disable indent */
import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { processflowProgressSchema } from "../domain/processflow-progress";

export const processflowProgressResponseSchema = z.object({
    id: z.number(),
    group: z.number(),
    currentStep: z.number().nullable().optional(),
    currentStage: z.number().nullable().optional(),
    userId: z.string(),
    data: z.any().nullable().optional(),
    client: z.number().nullable().optional(),
});

export type ProcessflowProgressData = z.infer<typeof processflowProgressResponseSchema>;

const getProcessflowProgressesResponseSchema = z.array(processflowProgressResponseSchema);

export const getProcessflowProgresses = async ({
    authToken,
    filters,
}: {
    authToken?: string;
    filters?: { userId?: string; userIds?: string[]; group?: number };
}) => {
    try {
        const response = await makeRequest({
            method: "get",
            path: "/processflow-progresses",
            authToken,
            queryParams: filters
                ? {
                      filter: JSON.stringify({
                          where: {
                              and: [
                                  ...(filters.userId ? [{ userId: filters.userId }] : []),
                                  ...(filters.group !== undefined ? [{ group: filters.group }] : []),
                                  ...(filters.userIds ? [{ userId: { inq: filters.userIds } }] : []),
                              ],
                          },
                          order: ["id DESC"],
                      }),
                  }
                : undefined,
        });

        const processflowProgressDtos = parseSchema(getProcessflowProgressesResponseSchema, response.data);

        const processflowProgresses = parseSchema(
            z.array(processflowProgressSchema),
            processflowProgressDtos.map((dto) => ({
                ...dto,
                data: dto.data ? JSON.parse(dto.data) : undefined,
            })),
        );

        return processflowProgresses;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
